<template>
  <div class="account-container">
    <h1>Your Account</h1>
    <p class="balance">Balance: {{ balance }}</p>
    <a :href="`${apiBaseUrl}/script/` + token + '.user.js'"
      ><button class="download-button">Install Userscript</button></a
    >
    <h1>Install Instructions</h1>
    <h2>To run the script you need to first install the tampermonkey extension</h2>
    <h2>First step, visit: <a href="https://www.tampermonkey.net/">Tampermonkey Website</a></h2>
    <h2>On the tampermonkey website, select your browser, then select "Get From Store", we recommened you use google chrome for the best experience.</h2>
    <h2>After installing tampermonkey, refresh this website, and click the green 'Install Userscript' button.</h2>
    <h2>You will be redirected to the tampermonkey extension, asking if you wish to install our userscript. Press 'Install' (or 'Update' if we release a new version)</h2>
    <h2>Happy Chatting!</h2>
    <!--<total-used-credits></total-used-credits>-->
  </div>
</template>

<script>
import axios from "axios";
//import TotalUsedCredits from "@/components/TotalUsedCredits.vue";

export default {
  components: {
    //TotalUsedCredits,
  },
  data() {
    return {
      balance: 0,
      token: localStorage.getItem("token"),
      apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "";
  },
  created() {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/api/users/balance/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        this.balance = response.data.balance;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style scoped>
body,
html {
  background-color: #000; /* or any color that matches your theme */
  background-size: 100% 100%;
}

body {
  margin: 0;
}

.account-container {
  background: url("https://wallpapers.com/images/featured-full/animated-space-fu6eyl0xafghub1h.jpg")
    no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px;
  text-align: center;
}

.balance,
.purchase-details {
  font-size: 2.0em;
  margin: 10px 0;
}

.download-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  margin: 20px 0;
}

.download-button:hover {
  background-color: #45a049;
}
</style>
