<template>
  <div class="sampler-config-page">
    <h2>Sampler Configuration</h2>

    <!-- Tab Navigation for Sampler Config -->
    <div class="sub-tabs">
      <button @click="currentTab = 'list'" :class="{ active: currentTab === 'list' }">List Samplers</button>
      <button @click="currentTab = 'create'" :class="{ active: currentTab === 'create' }">Create Sampler</button>
    </div>

    <!-- Tab Content -->
    <div v-if="currentTab === 'list'">
      <!-- Existing Sampler List -->
      <div v-if="loading" class="loading-message">
        Loading samplers...
      </div>

      <div v-else-if="samplers.length > 0">
        <table>
          <thead>
            <tr>
              <th class="tooltip-container">Sampler Name</th>
              <th class="tooltip-container">Repetition Penalty
                <span class="tooltip-text">Controls how much to penalize repetition. Higher values discourage repetition.</span>
              </th>
              <th class="tooltip-container">Temperature
                <span class="tooltip-text">Controls randomness. Lower values make the output more focused and deterministic.</span>
              </th>
              <th class="tooltip-container">Top_p
                <span class="tooltip-text">Nucleus sampling; controls diversity. Lower values make generation more selective.</span>
              </th>
              <th class="tooltip-container">Top_k
                <span class="tooltip-text">Limits sampling pool to top-k likely tokens. Higher values allow more diversity.</span>
              </th>
              <th class="tooltip-container">Presence Penalty
                <span class="tooltip-text">Discourages reusing words that have already appeared. Higher values discourage reuse.</span>
              </th>
              <th class="tooltip-container">Frequency Penalty
                <span class="tooltip-text">Reduces frequency of repeated words in the response. Higher values penalize frequent words.</span>
              </th>
              <th class="tooltip-container">Min_p
                <span class="tooltip-text">Sets a minimum probability for token selection, limiting lower-probability tokens.</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sampler in samplers" :key="sampler.name">
              <td>{{ sampler.name }}</td>

              <!-- Repetition Penalty Slider -->
              <td>
                <input
                  type="range"
                  min="1.0"
                  max="2.0"
                  step="0.1"
                  v-model.number="sampler.repetition_penalty"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.repetition_penalty }}
              </td>

              <!-- Temperature Slider -->
              <td>
                <input
                  type="range"
                  min="0.0"
                  max="2.0"
                  step="0.1"
                  v-model.number="sampler.temperature"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.temperature }}
              </td>

              <!-- Top_p Slider -->
              <td>
                <input
                  type="range"
                  min="0.0"
                  max="1.0"
                  step="0.01"
                  v-model.number="sampler.top_p"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.top_p }}
              </td>

              <!-- Top_k Slider -->
              <td>
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  v-model.number="sampler.top_k"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.top_k }}
              </td>

              <!-- Presence Penalty Slider -->
              <td>
                <input
                  type="range"
                  min="-2.0"
                  max="2.0"
                  step="0.1"
                  v-model.number="sampler.presence_penalty"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.presence_penalty }}
              </td>

              <!-- Frequency Penalty Slider -->
              <td>
                <input
                  type="range"
                  min="-2.0"
                  max="2.0"
                  step="0.1"
                  v-model.number="sampler.frequency_penalty"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.frequency_penalty }}
              </td>

              <td>
                <input
                  type="range"
                  min="0.0"
                  max="1.0"
                  step="0.01"
                  v-model.number="sampler.min_p"
                  @change="markAsChanged(sampler)"
                />
                {{ sampler.min_p }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Save Button -->
        <button class="save-button" @click="saveChanges">Save Changes</button>
      </div>

      <div v-else>
        <p>No samplers found.</p>
      </div>
    </div>

    <!-- Create New Sampler Tab -->
    <div v-if="currentTab === 'create'" class="create-sampler-container">
      <h3>Create New Sampler</h3>

      <form @submit.prevent="registerSamplerSettings">
        <div>
          <label for="alias" class="tooltip-container">Alias:
            <span class="tooltip-text">A unique name for identifying the sampler configuration.</span>
          </label>
          <input v-model="newSampler.alias" id="alias" required />
        </div>

        <!-- Repetition Penalty Slider with Tooltip -->
        <div class="tooltip-container">
          <label for="repetition_penalty" class="tooltip-container">Repetition Penalty
            <span class="tooltip-text">Controls how much to penalize repetition. Higher values discourage repetition.</span>
          </label>
          <input type="range" min="1.0" max="2.0" step="0.1" v-model.number="newSampler.repetition_penalty" />
          {{ newSampler.repetition_penalty }}
        </div>

        <!-- Temperature Slider with Tooltip -->
        <div class="tooltip-container">
          <label for="temperature" class="tooltip-container">Temperature
            <span class="tooltip-text">Controls randomness. Lower values make the output more focused and deterministic.</span>
          </label>
          <input type="range" min="0.0" max="2.0" step="0.1" v-model.number="newSampler.temperature" />
          {{ newSampler.temperature }}
        </div>

        <!-- Top_p Slider with Tooltip -->
        <div class="tooltip-container">
          <label for="top_p" class="tooltip-container">Top_p
            <span class="tooltip-text">Nucleus sampling; controls diversity. Lower values make generation more selective.</span>
          </label>
          <input type="range" min="0.0" max="1.0" step="0.01" v-model.number="newSampler.top_p" />
          {{ newSampler.top_p }}
        </div>

        <!-- Top_k Slider with Tooltip -->
        <div class="tooltip-container">
          <label for="top_k" class="tooltip-container">Top_k
            <span class="tooltip-text">Limits sampling pool to top-k likely tokens. Higher values allow more diversity.</span>
          </label>
          <input type="range" min="0" max="100" step="1" v-model.number="newSampler.top_k" />
          {{ newSampler.top_k }}
        </div>

        <!-- Presence Penalty Slider with Tooltip -->
        <div class="tooltip-container">
          <label for="presence_penalty" class="tooltip-container">Presence Penalty
            <span class="tooltip-text">Discourages reusing words that have already appeared. Higher values discourage reuse.</span>
          </label>
          <input type="range" min="-2.0" max="2.0" step="0.1" v-model.number="newSampler.presence_penalty" />
          {{ newSampler.presence_penalty }}
        </div>

        <!-- Frequency Penalty Slider with Tooltip -->
        <div class="tooltip-container">
          <label for="frequency_penalty" class="tooltip-container">Frequency Penalty
            <span class="tooltip-text">Reduces frequency of repeated words in the response. Higher values penalize frequent words.</span>
          </label>
          <input type="range" min="-2.0" max="2.0" step="0.1" v-model.number="newSampler.frequency_penalty" />
          {{ newSampler.frequency_penalty }}
        </div>

        <div class="tooltip-container">
          <label for="min_p" class="tooltip-container">Min_p
            <span class="tooltip-text">Sets a minimum probability for token selection, limiting lower-probability tokens.</span>
          </label>
          <input type="range" min="0.0" max="1.0" step="0.01" v-model.number="newSampler.min_p" />
          {{ newSampler.min_p }}
        </div>

        <button type="submit" class="create-button">Create Sampler</button>
      </form>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        currentTab: 'list', // Default to the list tab
        samplers: [],  // Store the samplers fetched from the API
        loading: true,  // Show loading while fetching samplers
        newSampler: {   // New sampler object for the form
          alias: '',
          repetition_penalty: 1.0,
          temperature: 1.0,
          top_p: 1.0,
          top_k: -1,
          presence_penalty: 0.0,
          frequency_penalty: 0.0,
          min_p: 0.0
        }
      };
    },
    methods: {
      async fetchSamplers() {
        // Fetch existing samplers from the API
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/get_samplers`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch samplers');
          }

          const data = await response.json();

          this.samplers = data.map((sampler) => ({
            name: sampler.alias,
            repetition_penalty: sampler.repetition_penalty,
            temperature: sampler.temperature,
            top_p: sampler.top_p,
            top_k: sampler.top_k,
            presence_penalty: sampler.presence_penalty,
            frequency_penalty: sampler.frequency_penalty,
            min_p: sampler.min_p,
            changed: false,  // Track if the sampler has been changed
          }));
        } catch (error) {
          console.error('Error fetching samplers:', error);
        } finally {
          this.loading = false;
        }
      },
      markAsChanged(sampler) {
        sampler.changed = true; // Mark as changed
      },
      async saveChanges() {
        const changedSamplers = this.samplers.filter(sampler => sampler.changed);
  
        if (changedSamplers.length === 0) {
          window.alert("No changes to save.");
          return;
        }
  
        // Use the registerSamplerSettings API for saving changed samplers
        try {
          for (const sampler of changedSamplers) {
            const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/register_sampler_settings`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                alias: sampler.name,
                repetition_penalty: sampler.repetition_penalty,
                temperature: sampler.temperature,
                top_p: sampler.top_p,
                top_k: sampler.top_k,
                presence_penalty: sampler.presence_penalty,
                frequency_penalty: sampler.frequency_penalty,
                min_p: sampler.min_p
              }),
            });
  
            if (!response.ok) {
              throw new Error(`Failed to save sampler: ${sampler.name}`);
            }
          }
  
          window.alert("All changes saved successfully!");
  
          // After saving, reset the changed state and refresh the samplers
          this.fetchSamplers();
        } catch (error) {
          window.alert('Error saving changes: ' + error.message);
          console.error('Error saving changes:', error);
        }
      },
      async registerSamplerSettings() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/register_sampler_settings`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.newSampler),
          });
  
          if (!response.ok) {
            throw new Error('Failed to register sampler settings');
          }
  
          const data = await response.json();
          console.log('Sampler registered successfully:', data);
  
          // Show success message
          window.alert('Sampler created successfully!');
  
          // Clear form fields
          this.newSampler = {
            alias: '',
            repetition_penalty: 1.0,
            temperature: 1.0,
            top_p: 1.0,
            top_k: -1,
            presence_penalty: 0.0,
            frequency_penalty: 0.0,
            min_p: 0.0
          };
  
          // Switch to the 'list' tab and refresh the data
          this.currentTab = 'list';
          this.fetchSamplers();
  
        } catch (error) {
          window.alert('Error registering sampler: ' + error.message);
          console.error('Error registering sampler:', error);
        }
      }
    },
    mounted() {
      this.fetchSamplers();
    }
  };
  </script>
  
  <style scoped>
  .sampler-config-page {
    padding: 20px;
    margin-top: 20px; /* Add margin to create space from the main tab navigation */
  }

  .create-sampler-container {
    padding-left: 70px; /* Add left padding */
  }
  
  .loading-message {
    text-align: center;
  }
  
  .sub-tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .sub-tabs button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    background-color: #616161;
  }
  
  .sub-tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead th, tbody td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  thead th {
    background-color: #000000;
  }
  
  .save-button, .create-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .save-button:hover, .create-button:hover {
    background-color: #0056b3;
  }

  .tooltip-container {
  position: relative;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
  </style>
  