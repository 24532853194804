<template>
    <div>
      <h2>DPO Stats Report</h2>
      <div class="date-inputs">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" v-model="startDate">
  
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" v-model="endDate">
      </div>
      <h3>Total DPO Sets: {{ totalDpoSets }}</h3>
      <table>
        <tr>
          <th>Username</th>
          <th>DPO Sets Count</th>
        </tr>
        <tr v-for="user in users" :key="user.username">
          <td>
            <a
              :href="`/dpo-messages?username=${user.username}&startDate=${startDate}&endDate=${endDate}`"
              target="_blank"
            >
              {{ user.username }}
            </a>
          </td>
          <td>{{ user.dpo_count }}</td>
        </tr>
      </table>
    </div>
  </template>
  
  <script>
  import { formatStartOfMonth, formatToday, formatDate } from '@/utils/dateUtils';
  
  export default {
    data() {
      const storedStartDate = localStorage.getItem('startDate');
      const storedEndDate = localStorage.getItem('endDate');
  
      return {
        users: [],
        totalDpoSets: 0,
        startDate: storedStartDate || formatStartOfMonth(),
        endDate: storedEndDate || formatToday(),
      };
    },
    created() {
      this.getToken();
      this.fetchDpoData();
    },
    methods: {
      getToken() {
        this.token = localStorage.getItem('token');
        if (!this.token) {
          console.error('No token found');
          return;
        }
      },
      async fetchDpoData() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/all-dpo-by-date/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.token}`,
            },
            body: JSON.stringify({
              start: this.startDate + 'T00:00:00',
              end: this.endDate + 'T23:59:59',
            }),
          });
          const data = await response.json();
          this.users = data.users;
          this.totalDpoSets = data.total_dpo_sets;
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      },
    },
    watch: {
      startDate(newVal) {
        const formattedDate = formatDate(new Date(newVal));
        localStorage.setItem('startDate', formattedDate);
        this.fetchDpoData();
      },
      endDate(newVal) {
        const formattedDate = formatDate(new Date(newVal));
        localStorage.setItem('endDate', formattedDate);
        this.fetchDpoData();
      },
    },
  };
  </script>
  
  <style>
  body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
  }
  
  h2, h3 {
    color: #0056b3;
  }
  
  .date-inputs {
    margin-bottom: 20px;
  }
  
  .date-inputs label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .date-inputs input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  th {
    background-color: #0066cc;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  </style>