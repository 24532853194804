<template>
  <div class="login-container">
    <form @submit.prevent="login" class="login-form">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username" required />

      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required />

      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

      <button type="submit">Log in</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "";
  },
  methods: {
    login() {
      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/api/users/login/`, {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          if(response.data.is_manager) this.$router.push("/messageEditing");
          else if(response.data.is_supervisor) this.$router.push("/supervisorPage");
          else this.$router.push("/account");
        })
        .catch((error) => {
          this.errorMessage = "Error! Maybe check credentials?";
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.login-container {
  background-color: black;
  background: url("@/assets/background.jpeg") no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-form input {
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.login-form button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #45a049;
}

.error-message {
  color: yellow;
  max-width: 165px;
  word-wrap: break-word;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}
</style>
