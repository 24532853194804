import { createRouter, createWebHistory } from 'vue-router'
import Login from './components/UserLogin.vue'
import Account from './components/UserAccount.vue'
import PandaStats from './components/UserStatistics.vue'
import PandaRecentMessages from './components/RecentMessages.vue'
import DatasetStatistics from './components/DatasetStatistics.vue'
import MessageEditing from './components/MessageEditing.vue'
import SupervisorPage from './components/SupervisorPage.vue'
import DpoStatsPage from './components/DpoStatsPage.vue'
import DpoMessages from './components/DpoMessages.vue'


const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    path: '/userStats',
    name: 'userStats',
    component: PandaStats
  },
  {
    path: '/recent-messages/:username',
    name: 'recentMessages',
    component: PandaRecentMessages,
    props: true
  },
  {
    path: '/datasetStats',
    name: 'datasetStats',
    component: DatasetStatistics
  },
  {
    path: '/messageEditing',
    name: 'messageEditing',
    component: MessageEditing
  },
  {
    path: '/supervisorPage',
    name: 'supervisor',
    component: SupervisorPage
  },
  {
    path: '/dpoStats',
    name: 'dpoStats',
    component: DpoStatsPage
  },
  {
    path: '/dpo-messages',
    name: 'dpoMessages',
    component: DpoMessages,
    props: route => ({
      username: route.query.username,
      startDate: route.query.startDate,
      endDate: route.query.endDate
    })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
