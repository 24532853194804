<template>
  <div class="ai-config-page">
    <h2>User AI Model Configuration</h2>

    <!-- Search Field -->
    <input
      type="text"
      placeholder="Search by Username"
      v-model="searchQuery"
      @input="filterUsers"
    />

    <div v-if="loading" class="loading-message">
      Loading users...
    </div>
    <div v-else-if="filteredUsers.length > 0">
      
      <!-- Bulk Change Row with Labels -->
      <div class="bulk-change-row">
        <label for="bulkModel">Change Models:</label>
        <select v-model="bulkModel" id="bulkModel" @change="applyBulkChange('model')">
          <option value="None">None</option>
          <option v-for="model in whitelistedModels" :key="model" :value="model">
            {{ model }}
          </option>
        </select>

        <label for="bulkModelSampler">Change Model Samplers:</label>
        <select v-model="bulkModelSampler" id="bulkModelSampler" @change="applyBulkChange('modelSampler')">
          <option value="None">None</option>
          <option v-for="sampler in modelSamplers" :key="sampler" :value="sampler">
            {{ sampler }}
          </option>
        </select>

        <label for="bulkAdapter">Change Adapters:</label>
        <select v-model="bulkAdapter" id="bulkAdapter" @change="applyBulkChange('adapter')">
          <option value="None">None</option>
          <option v-for="adapter in whitelistedAdapters" :key="adapter" :value="adapter">
            {{ adapter }}
          </option>
        </select>

        <label for="bulkAdapterSampler">Change Adapter Samplers:</label>
        <select v-model="bulkAdapterSampler" id="bulkAdapterSampler" @change="applyBulkChange('adapterSampler')">
          <option value="None">None</option>
          <option v-for="sampler in adapterSamplers" :key="sampler" :value="sampler">
            {{ sampler }}
          </option>
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Model</th>
            <th>Model Sampler Setting</th>
            <th>Adapter</th>
            <th>Adapter Sampler Setting</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.username">
            <td>{{ user.username }}</td>
            <td>
              <select v-model="user.model" @change="onModelChange(user)">
                <option value="None">None</option>
                <option v-for="model in whitelistedModels" :key="model" :value="model">
                  {{ model }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="user.samplerSettingModel" @change="onModelSamplerChange(user)">
                <option value="None">None</option>
                <option v-for="sampler in modelSamplers" :key="sampler" :value="sampler">
                  {{ sampler }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="user.adapter" @change="onAdapterChange(user)">
                <option value="None">None</option>
                <option v-for="adapter in whitelistedAdapters" :key="adapter" :value="adapter">
                  {{ adapter }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="user.samplerSettingAdapter" @change="onAdapterSamplerChange(user)">
                <option value="None">None</option>
                <option v-for="sampler in adapterSamplersForUser(user)" :key="sampler" :value="sampler">
                  {{ sampler }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Save Button -->
      <button class="save-button" @click="saveChanges">Save</button>
    </div>

    <div v-else>
      <p>No users found.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [], 
      filteredUsers: [], 
      whitelistedModels: [],
      whitelistedAdapters: [],
      modelSamplers: [],
      adapterSamplers: [],
      loading: true, 
      searchQuery: '',
      bulkModel: '',
      bulkModelSampler: '',
      bulkAdapter: '',
      bulkAdapterSampler: ''
    };
  },
  methods: {
    async fetchUserModels() {
      try {
        const bodyData = JSON.stringify({
          username: ''  // Sending an empty string for the username as required
        });

        console.log('Fetching user models...');
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/get_user_models`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: bodyData,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user models');
        }

        const data = await response.json();
        console.log('User models data:', data);

        // Parse the API response
        this.users = data.map((entry) => {
          return {
            username: entry[0],
            model: entry[1][0],
            samplerSettingModel: entry[1][1],  // Model sampler
            adapter: entry[2][0],
            samplerSettingAdapter: entry[2][1],  // Adapter sampler
            changed: false
          };
        });

        // Initially, all users are shown in the table
        this.filteredUsers = [...this.users];

        // Keep a copy of the original data to track changes later
        this.originalUsers = JSON.parse(JSON.stringify(this.users));
        
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;  // Stop loading after the data is fetched
      }
    },
    async fetchAvailableModelsAndAdapters() {
      try {
        console.log('Fetching available models and adapters...');
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/get_available_models_and_adapters`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch available models and adapters');
        }

        const data = await response.json();
        console.log('Available models and adapters:', data);

        this.whitelistedModels = data.models;
        this.whitelistedAdapters = data.adapters;

      } catch (error) {
        console.error('Error fetching available models and adapters:', error);
      }
    },
    async fetchSamplers() {
      try {
        console.log('Fetching samplers...');
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/get_samplers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch samplers');
        }

        const data = await response.json();
        console.log('Samplers:', data);

        // Assuming the API provides separate arrays for model and adapter samplers
        this.modelSamplers = data.map(s => s.alias);  // Assuming we use alias as sampler names
        this.adapterSamplers = data.map(s => s.alias);  // Same for adapter samplers

      } catch (error) {
        console.error('Error fetching samplers:', error);
      }
    },
    filterUsers() {
      this.filteredUsers = this.users.filter(user =>
        user.username.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    onModelChange(user) {
      console.log('Model changed for:', user.username, 'to', user.model);
      if (user.model === 'None') {
        user.samplerSettingModel = 'None';
        user.adapter = 'None';
        user.samplerSettingAdapter = 'None';
      }
      this.markAsChanged(user);
    },
    onModelSamplerChange(user) {
      console.log('Model Sampler changed for:', user.username, 'to', user.samplerSettingModel);
      if (user.samplerSettingModel !== 'None') {
        user.adapter = 'None';
        user.samplerSettingAdapter = 'None';
      }
      this.markAsChanged(user);
    },
    onAdapterChange(user) {
      console.log('Adapter changed for:', user.username, 'to', user.adapter);
      if (user.adapter !== 'None') {
        // Set model sampler to 'None'
        user.samplerSettingModel = 'None';

        // Set the adapter sampler to the first available item in the list
        if (this.adapterSamplers.length > 0) {
          user.samplerSettingAdapter = this.adapterSamplers[0];  // Set to the first sampler
        }
      } else {
        // If adapter is None, also set adapter sampler to None
        user.samplerSettingAdapter = 'None';
      }
      this.markAsChanged(user);
    },
    onAdapterSamplerChange(user) {
      console.log('Adapter Sampler changed for:', user.username, 'to', user.samplerSettingAdapter);
      this.markAsChanged(user);
    },
    adapterSamplersForUser(user) {
      // Filter out "None" for adapter sampler if adapter is chosen
      return user.adapter !== 'None'
        ? this.adapterSamplers.filter(sampler => sampler !== 'None')
        : this.adapterSamplers;
    },
    markAsChanged(user) {
      user.changed = true;
    },
    applyBulkChange(type) {
      if (type === 'model' && this.bulkModel) {
        this.filteredUsers.forEach(user => {
          user.model = this.bulkModel === 'None' ? 'None' : this.bulkModel;
          this.onModelChange(user);
        });
      }
      if (type === 'modelSampler' && this.bulkModelSampler) {
        this.filteredUsers.forEach(user => {
          user.samplerSettingModel = this.bulkModelSampler === 'None' ? 'None' : this.bulkModelSampler;
          this.onModelSamplerChange(user);
        });
      }
      if (type === 'adapter' && this.bulkAdapter) {
        this.filteredUsers.forEach(user => {
          user.adapter = this.bulkAdapter === 'None' ? 'None' : this.bulkAdapter;
          this.onAdapterChange(user);
        });
      }
      if (type === 'adapterSampler' && this.bulkAdapterSampler) {
        this.filteredUsers.forEach(user => {
          user.samplerSettingAdapter = this.bulkAdapterSampler === 'None' ? 'None' : this.bulkAdapterSampler;
          this.onAdapterSamplerChange(user);
        });
      }
    },
    saveChanges() {
      const changedUsers = this.users.filter(user => user.changed);

      if (changedUsers.length === 0) {
        alert('No changes to save.');
        return;  // Exit if there are no changes
      }

      // Validation: Ensure that none of the users have 'None' or empty models
      const invalidUsers = changedUsers.filter(user => !user.model || user.model === 'None');
      if (invalidUsers.length > 0) {
        alert(`The following users have no valid model selected: ${invalidUsers.map(user => user.username).join(', ')}. Please select a model for these users.`);
        return;  // Exit if any users have an invalid model
      }

      // Format the payload
      const payload = {
        usernames: changedUsers.map(user => ({
          username: user.username,
          model_alias: user.model !== 'None' ? user.model : null,
          model_sampler_alias: user.samplerSettingModel !== 'None' ? user.samplerSettingModel : null,
          adapter_alias: user.adapter !== 'None' ? user.adapter : null,
          adapter_sampler_alias: user.samplerSettingAdapter !== 'None' ? user.samplerSettingAdapter : null,
        }))
      };

      // Send the request to save the changes
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/set_user_models`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (response.ok) {
            alert('Changes saved successfully!');  // Display success popup
            
            // Clear the changed status
            this.users.forEach(user => user.changed = false);
          } else {
            alert('Failed to save changes.');  // Display failure popup
            console.error('Failed to save changes');
          }
        })
        .catch(error => {
          alert('Error during save operation.');
          console.error('Error during save operation:', error);
        });
    }
  },
  mounted() {
    this.fetchUserModels();
    this.fetchAvailableModelsAndAdapters();
    this.fetchSamplers();  // Fetch available samplers on component load
  }
};
</script>

<style scoped>
.ai-config-page {
  padding: 20px;
}

.loading-message {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th, tbody td {
  padding: 10px;
  border: 1px solid #ccc;
}

thead th {
  background-color: #000000;
}

.bulk-change-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

/* Save Button Style */
.save-button {
  position: fixed;
  bottom: 10px;
  right: 20px;
  padding: 15px 30px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}
</style>
