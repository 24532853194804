<template>
    <div>
      <h2>DPO Messages for {{ username }}</h2>
      <div class="date-inputs">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" v-model="startDate">
  
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" v-model="endDate">
      </div>
      <button @click="fetchDpoMessages">Fetch Messages</button>
      <table v-if="dpoMessages.length > 0">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Message History</th>
            <th>Generated Answers</th>
            <th>Edited Chosen Answer</th>
            <th>Edited by User</th>
            <th>Similarity Rate (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dpo, index) in dpoMessages" :key="index">
            <td>{{ formatTimestamp(dpo.timestamp) }}</td>
            <td v-html="getLastThreeMessages(dpo.previous_messages)"></td>
            <td>
              <ul>
                <li
                  v-for="(answer, i) in parseGeneratedAnswers(dpo.generated_answers)"
                  :key="i"
                  :class="{ 'highlight': answer === dpo.chosen_answer }"
                >
                  {{ answer }}
                </li>
              </ul>
            </td>
            <td>{{ dpo.edited_chosen_answer }}</td>
            <td>{{ dpo.chosen_answer_edited_by_user ? "Yes" : "No" }}</td>
            <td :class="getSimilarityClass(dpo.chosen_answer, dpo.edited_chosen_answer)">
            {{ calculateSimilarity(dpo.chosen_answer, dpo.edited_chosen_answer) }}%
          </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        No DPO messages found for this user.
      </div>
      
      <div class="pagination-controls" v-if="totalPages > 1">
        <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import { formatDate, formatToday, formatStartOfMonth } from '@/utils/dateUtils';
import levenshtein from 'js-levenshtein';

export default {
  name: 'DpoMessages',
  props: {
    username: String,
  },
  data() {
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');
    return {
      dpoMessages: [],
      startDate: storedStartDate || formatStartOfMonth(),
      endDate: storedEndDate || formatToday(),
      token: null,
      currentPage: 1,
      pageSize: 20,
      totalPages: 1,
    };
  },
  created() {
    this.getToken();
    this.fetchDpoMessages();
  },
  methods: {
    getToken() {
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async fetchDpoMessages() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/dpo-messages/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({
            start: this.startDate + 'T00:00:00',
            end: this.endDate + 'T23:59:59',
            username: this.username,
            page: this.currentPage,
            page_size: this.pageSize,
          }),
        });
        const data = await response.json();
        this.dpoMessages = data.dpo_messages;
        this.totalPages = Math.ceil(data.total_count / this.pageSize);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    goToPage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.fetchDpoMessages();
    },
    getLastThreeMessages(messages) {
        // Parse the messages and handle any parsing errors
        const parsedMessages = messages
            .map((msg) => {
            try {
                if (typeof msg === 'object' && msg !== null) {
                return msg;
                }
                const sanitizedMsg = msg.replace(/'/g, '"').replace(/None/g, 'null');
                return JSON.parse(sanitizedMsg);
            } catch (error) {
                console.error("Error parsing message:", msg, error);
                return null;
            }
            })
            .filter((msg) => msg !== null); // Filter out any null messages from failed parsing

        // Get the last 3 messages in the correct order (no reverse needed)
        const lastThreeMessages = parsedMessages.slice(-3);

        // Format each message with role and content, adding <br> for new lines
        return lastThreeMessages
            .map(msg => `<strong>${msg.role.toUpperCase()}:</strong> ${msg.content}`)
            .join('<br><br>');
        },
    parseGeneratedAnswers(generatedAnswers) {
        return generatedAnswers.map((answer) => {
            try {
            // Check if `answer` is an object; if so, access the message directly
            if (typeof answer === 'object' && answer !== null) {
                return answer.message || 'No message found';
            }

            // Otherwise, parse as a string (legacy or improperly formatted data)
            const sanitizedAnswer = answer.replace(/'/g, '"').replace(/None/g, 'null');
            const parsedAnswer = JSON.parse(sanitizedAnswer);
            return parsedAnswer.message || 'No message found';
            } catch (error) {
            console.error("Error parsing generated answer:", answer, error);
            return 'Parsing error';
            }
        });
    },
    calculateSimilarity(chosenAnswer, editedChosenAnswer) {
      if (!chosenAnswer || !editedChosenAnswer) {
        return 0;
      }
      const distance = levenshtein(chosenAnswer, editedChosenAnswer);
      const maxLength = Math.max(chosenAnswer.length, editedChosenAnswer.length);
      const similarity = ((maxLength - distance) / maxLength) * 100;
      return similarity.toFixed(2);
    },
    getSimilarityClass(chosenAnswer, editedChosenAnswer) {
      const similarity = this.calculateSimilarity(chosenAnswer, editedChosenAnswer);
      return similarity === "100.00" ? "similarity-green" : "similarity-yellow";
    }
  },
  watch: {
    startDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('startDate', formattedDate);
      this.currentPage = 1;
      this.fetchDpoMessages();
    },
    endDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('endDate', formattedDate);
      this.currentPage = 1;
      this.fetchDpoMessages();
    },
  },
};
  </script>
  
  <style>
  .highlight {
  background-color: #dff0d8;
  font-weight: bold;
}
.similarity-green {
  background-color: #4CAF50;
  color: white;
}

.similarity-yellow {
  background-color: #FFEB3B;
  color: black;
}

  .date-inputs {
    margin-bottom: 20px;
  }
  
  .date-inputs label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .date-inputs input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  th {
    background-color: #0066cc;
    color: white;
  }
  
  ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  </style>