<template>
  <div>
    <div v-if="!dataLoaded">Loading...</div>
    <div v-else>
      <div class="tab-container">
    <div class="tabs">
      <button
        v-for="tab in tabs"
        :key="tab.name"
        :class="['tab-button', { active: currentTab === tab }]"
        @click="currentTab = tab"
      >
        {{ tab.name }}
      </button>
    </div>

    <div class="tab-content">
      <div v-if="currentTab.name === 'Correction'">
        <!-- <button class="emergency-reset-btn" @click="emergencyReset">Emergency Reset</button> -->
        <div class="profiles-container">
          <div class="profile real-profile" v-if="profiles.real">
            <h3>Real Profile</h3>
            <p>Username: {{ profiles.real.username }}</p>
            <p>Name: {{ profiles.real.name }}</p>
            <p>Gender: {{ profiles.real.gender }}</p>
            <p>Age: {{ profiles.real.age }}</p>
            <p>Country: {{ profiles.real.country }}</p>
            <p>Region: {{ profiles.real.region }}</p>
            <p>Eye Color: {{ profiles.real.eye_colour }}</p>
            <p>Hair Color: {{ profiles.real.hair_colour }}</p>
            <p>Smoker: {{ profiles.real.smoker }}</p>
            <p>Height: {{ profiles.real.height }}</p>
            <p>Body Type: {{ profiles.real.body_type }}</p>
            <p>About Me: {{ profiles.real.about_me }}</p>
            <p>Sexual Preference: {{ profiles.real.sexual_preference }}</p>
          </div>
          <div class="profile fake-profile" v-if="profiles.fake">
            <h3>Fake Profile</h3>
            <p>Username: {{ profiles.fake.username }}</p>
            <p>Name: {{ profiles.fake.name }}</p>
            <p>Gender: {{ profiles.fake.gender }}</p>
            <p>Age: {{ profiles.fake.age }}</p>
            <p>Country: {{ profiles.fake.country }}</p>
            <p>Region: {{ profiles.fake.region }}</p>
            <p>Eye Color: {{ profiles.fake.eye_colour }}</p>
            <p>Hair Color: {{ profiles.fake.hair_colour }}</p>
            <p>Smoker: {{ profiles.fake.smoker }}</p>
            <p>Height: {{ profiles.fake.height }}</p>
            <p>Body Type: {{ profiles.fake.body_type }}</p>
            <p>About Me: {{ profiles.fake.about_me }}</p>
            <p>Sexual Preference: {{ profiles.fake.sexual_preference }}</p>
          </div>
        </div>
        <div class="chat-container">
            <div v-if="conversation">
                <div v-for="message in conversation" :key="message.id"
                    :class="['message', { 'user-message': message.role === 'user', 'operator-message': message.role === 'assistant' }]">
                    <div class="message-header">
                      <span class="sender-label">
                        {{ message.role === 'user' ? profiles.real.username : profiles.fake.username }}
                      </span>
                      <span class="timestamp">{{ new Date(message.timestamp).toLocaleString() }}</span>
                    </div>
                    <div class="message-body">
                        <div>
                            <p v-if="message.is_corrected">{{ message.corrected_content }}</p>
                            <p v-if="!message.is_corrected">{{ message.content }}</p>
                            <!-- <span v-if="message.is_corrected" class="status-label">Corrected</span> -->
                            <div v-if="message.is_corrected" class="message-corrected-info">
                              <span class="status-label">Corrected</span>
                              <button class="reset-btn" @click="resetCorrection(message)">Reset</button>
                            </div>
                            <div v-if="!message.is_corrected && message.role === 'assistant'" class="quality-buttons">
                              <button class="high-quality-btn" @click="openConfirmationModal(message)">High Quality</button>
                              <button class="low-quality-btn" @click="openLowQualityCorrectionModal(message)">Low Quality</button>
                            </div>
                            <div v-if="!message.is_corrected && message.role === 'user'" class="quality-buttons">
                              <!-- <button class="high-quality-btn" @click="openConfirmationModal(message)">High Quality</button> -->
                              <button class="low-quality-btn" @click="openLowQualityCorrectionModal(message)">Edit</button>
                            </div>
                            <div v-if="message.image_url">
                              <img :src="message.image_url" alt="User uploaded image" />
                              <span v-if="message.is_image_labeled" class="status-label">Labeled: {{ message.image_label }}</span>
                              <button v-if="!message.is_image_labeled" class="label-button" @click="openImageLabelModal(message)" :disabled="true">Label</button>
                              <button v-if="message.is_image_labeled" class="reset-btn" @click="resetImageLabel(message)" :disabled="true">Reset Label</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="submit-button-container">
            <!-- <button :disabled="!allMessagesCorrected" @click="submitCorrections" class="submit-button">
                Load Next Conversation
            </button> -->
            <button :disabled="!allMessagesCorrected || isLoadingNextConversation" @click="submitCorrections" class="submit-button">
              <span v-if="isLoadingNextConversation">Loading...</span>
              <span v-else>Load Next Conversation</span>
            </button>
        </div>
    </div>
    <div v-if="currentTab.name === 'Statistics'">
      <div v-if="statistics">
        <!-- Display your statistics here -->
        <div class="statistics-container">
          <!-- Existing statistics items -->
          <div class="date-inputs">
            <label for="startDate">Start Date:</label>
            <input type="date" id="startDate" v-model="startDate">

            <label for="endDate">End Date:</label>
            <input type="date" id="endDate" v-model="endDate">
          </div>
          <div class="statistic-item">
            <span class="statistic-label">Total Submissions:</span>
            <span class="statistic-value">{{ statistics.total_submits }}</span>
          </div>
          <div class="statistic-item">
            <span class="statistic-label">Total High Quality Selections:</span>
            <span class="statistic-value">{{ statistics.total_high_quality_selections }}</span>
          </div>
          <div class="statistic-item">
            <span class="statistic-label">Total Low Quality Selections:</span>
            <span class="statistic-value">{{ statistics.total_low_quality_selections }}</span>
          </div>
          <div class="statistic-item">
            <span class="statistic-label">Total Images Labeled:</span>
            <span class="statistic-value">{{ statistics.total_images_labeled }}</span>
          </div>
          <div class="statistic-item">
            <span class="statistic-label">Total Client Edits:</span>
            <span class="statistic-value">{{ statistics.total_client_edits }}</span>
          </div>
          <div v-if="statistics.corrections_by_day && statistics.corrections_by_day.length" class="corrections-by-day">
            <h3>Corrections by Day:</h3>
            <ul>
              <li v-for="(correction, idx) in statistics.corrections_by_day" :key="idx">
                {{ correction.date }}: {{ correction.count }}
              </li>
            </ul>
          </div>
          <div v-if="recentCorrections && recentCorrections.length" class="recent-corrections">
            <h3>Recent Corrections</h3>
            <div v-if="currentCorrection">
              <div class="corrections-table">
                <div class="table-header">
                  <div class="header-item">Content</div>
                  <div class="header-item">Corrected Content</div>
                </div>
                <div v-for="(message, index) in currentCorrection.messages" :key="'message-' + index" 
                    :class="{'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant'}" class="table-row">
                  <div class="content-item">{{ message.content }}</div>
                  <div class="content-item">
                    <!-- Inline Conditional Rendering -->
                    {{ message.content === message.corrected_content && message.was_high_quality ? 'MESSAGE WAS HIGH QUALITY' : message.corrected_content }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination">
              <button v-for="page in totalPages" :key="page" @click="setCurrentPage(page)" :class="{'active': currentPage === page}">
                {{ page }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Loading statistics...</p>
      </div>
    </div>
    </div>

    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <p>Are you sure you want to mark this message as high quality?</p>
        <button @click="markAsCorrectedAndCloseModal">Confirm</button>
        <button @click="showModal = false">Cancel</button>
      </div>
    </div>
  </div>

  <div v-if="imageLabelModal.show" class="modal">
    <div class="modal-content">
      <img :src="imageLabelModal.image_url" alt="Image to label" />
      <textarea v-model="imageLabelModal.description" placeholder="Enter image description here"></textarea>
      <button @click="openConfirmImageLabelModal" :disabled="!imageLabelModal.description.trim()">Submit</button>
      <button @click="closeImageLabelModal">Cancel</button>
    </div>
  </div>

  <div v-if="confirmImageLabelModal.show" class="modal">
    <div class="modal-content">
      <p>Are you sure you want to submit this image label?</p>
      <button @click="confirmSubmitImageLabel">Yes</button>
      <button @click="closeConfirmImageLabelModal">No</button>
    </div>
  </div>

  <div v-if="lowQualityCorrectionModal.show" class="modal">
    <div class="modal-content">
      <p>Original Message:</p>
      <div class="original-message">{{ lowQualityCorrectionModal.content }}</div>
      <textarea v-model="lowQualityCorrectionModal.corrected_content"></textarea>
      <button class="submit-correction-btn" 
              @click="openConfirmLowQualityCorrectionModal(lowQualityCorrectionModal.id)"
              :disabled="isSubmitDisabled">
        Submit
      </button>
      <button class="cancel-correction-btn" @click="closeLowQualityCorrectionModal">Cancel</button>
    </div>
  </div>

  <div v-if="confirmLowQualityCorrectionModal.show" class="modal">
  <div class="modal-content">
    <p>Are you sure you want to submit this corrected message?</p>
    <button @click="confirmLowQualityCorrection">Yes</button>
    <button @click="closeConfirmLowQualityCorrectionModal">No</button>
  </div>
</div>

    </div>
  </div>
  
</template>

<script>
import { formatStartOfMonth, formatToday, formatDate} from '@/utils/dateUtils';
export default {
  name: 'MessageEditing',
  data() {
    const initialTabs = [
      { name: 'Correction' },
      { name: 'Statistics' }
    ];
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');

    return {
      token: null,
      startDate: storedStartDate || formatStartOfMonth(),
      endDate: storedEndDate || formatToday(),
      currentPage: 1,
      tabs: initialTabs,
      currentTab: initialTabs[0],
      showModal: false,
      selectedMessageId: null,
      conversation: null, // Set to null initially
      statistics: null,
      recentCorrections: null,
      usernamesData: null,
      dataLoaded: false,
      isLoadingNextConversation: false,
      profiles: {
        fake: null,
        real: null
      },
      imageLabelModal: {
        show: false,
        image_url: '',
        image_label: '',
        description: '',
        id: null
      },
      confirmImageLabelModal: {
        show: false,
        id: null,
      },
      lowQualityCorrectionModal: {
        show: false,
        content: '',
        corrected_content: '',
        id: null
      },
      confirmLowQualityCorrectionModal: {
      show: false,
      id: null
    },
    };
  },
  computed: {
    allMessagesCorrected() {
      // Ensure that this.conversation is defined and is an array
      if (!Array.isArray(this.conversation)) {
        return false;
      }
      // Check if every message in the conversation meets the criteria
      return this.conversation.every(message => {
        // If a message contains an image, check if it's labeled; if no image, consider it corrected
        // const imageCorrected = message.image_url ? message.is_image_labeled : true;
        const imageCorrected = true; //temporary disabling of image labeling.
        // Check if the text content of the message is corrected, but only for assistant messages
        const contentCorrected = message.role === 'assistant' ? message.is_corrected : true;
        
        return imageCorrected && contentCorrected;
      });
    },

    isSubmitDisabled() {
      return this.lowQualityCorrectionModal.corrected_content === this.lowQualityCorrectionModal.content;
    },
    currentCorrection() {
      const index = this.currentPage - 1; // Adjust for array index starting at 0
      return this.recentCorrections[index]; // Get the current correction object
    },
    totalPages() {
      return this.recentCorrections.length; // Total number of correction objects
    }
  },
  watch: {
    'currentTab.name'(newTabName) {
      if (newTabName === 'Statistics') {
        this.fetchStatistics();
        this.getRecentCorrections();
      }
    },
    startDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('startDate', formattedDate);
      this.fetchStatistics();
    },
    endDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('endDate', formattedDate);
      this.fetchStatistics();
    },
  },
  
  methods: {
    getToken(){
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    async fetchConversation() 
    {
      const localData = localStorage.getItem('conversation');
      if (localData) {
        this.conversation = JSON.parse(localData);
      } else {
        try {
          await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/request_corrections/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
          
        }).then(response => response.json())
        .then(data => {
          console.log('Fetched conversation:', data);
          this.conversation = data;
          // this.fetchConvoUsernames();
        })
        .catch(error => console.error('Error:', error));
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
      }
    },

    async fetchConvoUsernames() {
      // Check if the conversation array has at least one message
      if (this.conversation && this.conversation.length > 0) {
        const conversationId = this.conversation[0].conversation_id; // Retrieve conversation ID from the first message
        console.log("Using id:", conversationId);

        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_convo_usernames/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.token}`,
            },
            body: JSON.stringify({ conversation_id: conversationId })
          });

          if (response.ok) {
            this.usernamesData = await response.json();
            console.log('Usernames:', this.usernamesData);
            // Now you can use the usernamesData as needed
            // this.retrieveProfiles();
          } else {
            const errorData = await response.json();
            console.error('Error fetching conversation usernames:', errorData.message);
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      } else {
        console.error('No messages available to retrieve the conversation ID');
      }
    },

    async fetchProfile(username) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/retrieve_profile/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({ username })
        });

        if (response.ok) {
          return await response.json();  // Returning the profile data
        } else {
          console.error('Profile retrieval failed:', await response.json());
          return null;  // Handle the error appropriately
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        return null;  // Handle network errors
      }
    },

    async retrieveProfiles() {
      for (const key of Object.keys(this.usernamesData)) {
        const username = this.usernamesData[key];
        this.profiles[key] = await this.fetchProfile(username);
        console.log('Profile retrieved:', this.profiles[key]);
      }
    },

    async submitCorrections() {
      // try {
      //   const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/receive_corrections/`, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Token ${this.token}`,
      //     },
      //     body: JSON.stringify(this.conversation)
      //   });

      //   const data = await response.json();
      //   console.log('Response from corrections submission:', data);

      //   if (response.ok) {
      //     // Handle successful submission
      //     console.log('Corrections successfully submitted.');
      //     localStorage.removeItem('conversation');
      //     this.resetState();
      //     this.fetchConversation();
      //   } else {
      //     // Handle errors
      //     console.error('Error submitting corrections:', data.message);
      //   }
      // } catch (error) {
      //   console.error('There was a problem with the fetch operation:', error);
      // }
      this.isLoadingNextConversation = true;
      localStorage.removeItem('conversation');
      this.fetchConversation();
      this.isLoadingNextConversation = false;
    },

    async submitSingleCorrection(message) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/receive_corrections/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify([message]) // Encapsulating the single message in an array
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('conversation', JSON.stringify(this.conversation));
          console.log('Single correction successfully submitted.');
          return true; // Indicating success
        } else {
          console.error('Error submitting single correction:', data.message);
          return false; // Indicating failure
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return false; // Indicating failure
      }
    },

    async fetchStatistics() 
    {
        try {
          await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/correction_statistics/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({
            start: this.startDate + 'T00:00:00',
            end: this.endDate + 'T23:59:59',
          }),       
        }).then(response => response.json())
        .then(data => {
          console.log(data);
          this.statistics = data;
        })
        .catch(error => console.error('Error:', error));
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
      
    },

    async getRecentCorrections()
    {
        try {
          await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_recent_corrections/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
          
        }).then(response => response.json())
        .then(data => {
          console.log(data);
          this.recentCorrections = data;
        })
        .catch(error => console.error('Error:', error));
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },

    reloadChats() {
      const savedConversation = localStorage.getItem('conversation');
      console.log("Local Storage Convo:", savedConversation);
      if (savedConversation) {
        this.conversation = JSON.parse(savedConversation);
      }
    },
    openConfirmationModal(message) {
      this.selectedMessageId = message.id;
      this.showModal = true;
    },
    markAsCorrectedAndCloseModal() {
      this.markAsCorrected(this.selectedMessageId);
      this.showModal = false;
    },
    async markAsCorrected(id) {
      const message = this.conversation.find(msg => msg.id === id);
      if (message) {
        // Saving original state before making changes
        const originalState = {
          is_corrected: message.is_corrected,
          was_high_quality: message.was_high_quality,
          corrected_content: message.corrected_content,
        };

        // Updating the message state
        message.is_corrected = true;
        message.was_high_quality = true;
        message.corrected_content = message.content;

        // Attempt to submit the single correction
        const submissionSuccess = await this.submitSingleCorrection(message);

        if (!submissionSuccess) {
          // Revert changes if submission fails
          message.is_corrected = originalState.is_corrected;
          message.was_high_quality = originalState.was_high_quality;
          message.corrected_content = originalState.corrected_content;
        }
      }
    },
    openImageLabelModal(message) {
      this.imageLabelModal.show = true;
      this.imageLabelModal.image_url = `${message.image_url}`;
      this.imageLabelModal.id = message.id;
    },

    openConfirmImageLabelModal() {
      this.confirmImageLabelModal.id = this.imageLabelModal.id;
      this.confirmImageLabelModal.show = true;
    },

    async confirmSubmitImageLabel() {
      const message = this.conversation.find(msg => msg.id === this.imageLabelModal.id);
      if (message) {
        // Saving original state before making changes
        const originalState = {
          is_image_labeled: message.is_image_labeled,
          image_label: message.image_label,
        };

        // Updating the message state
        message.is_image_labeled = true;
        message.image_label = this.imageLabelModal.description;

        // Attempt to submit the single correction
        const submissionSuccess = await this.submitSingleCorrection(message);

        if (!submissionSuccess) {
          // Revert changes if submission fails
          message.is_image_labeled = originalState.is_image_labeled;
          message.image_label = originalState.image_label;
        }

        this.closeImageLabelModal();
        this.confirmImageLabelModal.show = false;
      }
    },

    closeImageLabelModal() {
      this.imageLabelModal.show = false;
      this.imageLabelModal.description = '';
      this.imageLabelModal.id = null;
    },

    closeConfirmImageLabelModal() {
      this.confirmImageLabelModal.show = false;
      this.imageLabelModal.show = true;  // Reopen the image labeling modal
    },

    openLowQualityCorrectionModal(message) {
      console.log("MESSAGE", message.content);
      this.lowQualityCorrectionModal.content = message.content;
      this.lowQualityCorrectionModal.corrected_content = message.content; // Pre-fill with original message
      this.lowQualityCorrectionModal.id = message.id;
      this.lowQualityCorrectionModal.show = true;
    },

    closeLowQualityCorrectionModal() {
      this.lowQualityCorrectionModal.show = false;
      // this.lowQualityCorrectionModal.originalMessage = '';
      this.lowQualityCorrectionModal.corrected_content = '';
      this.lowQualityCorrectionModal.id = null;
    },

    closeConfirmLowQualityCorrectionModal() {
      this.confirmLowQualityCorrectionModal.show = false;
    },
    openConfirmLowQualityCorrectionModal() {
      this.confirmLowQualityCorrectionModal.id = this.lowQualityCorrectionModal.id;
      this.confirmLowQualityCorrectionModal.show = true;
    },

    async confirmLowQualityCorrection() {
      const message = this.conversation.find(msg => msg.id === this.confirmLowQualityCorrectionModal.id);
      if (message) {
        // Saving original state before making changes
        const originalState = {
          is_corrected: message.is_corrected,
          corrected_content: message.corrected_content,
          was_low_quality: message.was_low_quality,
        };

        // Updating the message state
        message.corrected_content = this.lowQualityCorrectionModal.corrected_content;
        message.is_corrected = true;
        message.was_low_quality = true;

        // Attempt to submit the single correction
        const submissionSuccess = await this.submitSingleCorrection(message);

        if (!submissionSuccess) {
          // Revert changes if submission fails
          message.is_corrected = originalState.is_corrected;
          message.corrected_content = originalState.corrected_content;
          message.was_low_quality = originalState.was_low_quality;
        } 

        // Close modals
        this.closeConfirmLowQualityCorrectionModal();
        this.closeLowQualityCorrectionModal();
      }
    },

    resetCorrection(message) {
      if (confirm('Are you sure you want to reset the message state?')) {
        // Revert the message content and status
        message.is_corrected = false;
        message.corrected_content = null; // Or set it to the original content if you wish
        message.was_high_quality = false;
        message.was_low_quality = false;
        // Update localStorage if you're using it to store the state
        localStorage.setItem('conversation', JSON.stringify(this.conversation));
        this.reloadChats();
      }
    },

    resetImageLabel(message) {
      // Reset the labeled status and remove the label
      if(confirm('Are you sure you want to reset the label state?')){
        message.is_image_labeled = false;
        message.image_label = '';
        localStorage.setItem('conversation', JSON.stringify(this.conversation));
        this.reloadChats();
      }
    },

    resetState() {
      // Resetting directly might not reflect in the UI correctly if the properties are objects,
      // so it's better to replace them with new objects or reset individual properties.
      this.showModal = false;
      this.selectedMessageId = null;
      this.conversation = null; // Assuming you want to fetch fresh data
      this.statistics = null;
      this.recentCorrections = null;
      
      // Resetting object properties
      this.imageLabelModal = { show: false, image_url: '', image_label: '', id: null };
      this.confirmImageLabelModal = { show: false, id: null };
      this.lowQualityCorrectionModal = { show: false, content: '', corrected_content: '', id: null };
      this.confirmLowQualityCorrectionModal = { show: false, id: null };

      // If there's a need to reset tabs to their initial state, do so, but it might not be necessary
      // unless the tabs themselves are dynamically altered based on interactions.
    },

    emergencyReset() {
      if (confirm('Are you sure you want to reset the conversation data?')) {
        localStorage.removeItem('conversation'); // Clears only the conversation data
        // Optionally, refresh the page or update state to reflect changes
        this.conversation = null; // Reset conversation data in the Vue instance
        // If you need to refresh the conversation or re-fetch data, call the relevant method here
        this.fetchConversation(); // Assuming fetchConversation is your method to fetch conversation data
      }
    }
  },

  async mounted() {
    this.getToken();
    await this.fetchConversation();
    await this.fetchConvoUsernames();
    await this.retrieveProfiles();
    this.dataLoaded = true;
  }
};
</script>

<style>
.tab-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .tabs {
  display: flex;
  margin-bottom: 20px;
} */
.tabs {
    position: fixed;
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    z-index: 100; /* Ensure it's above other elements */
    background-color: white; /* For better visibility */
    padding: 5px; /* For spacing around the buttons */
    border-radius: 8px; /* For rounded corners */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* For subtle shadow */
  }

.tab-button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: #f3f3f3;
  cursor: pointer;
}

.tab-button.active {
  background-color: #0056b3;
  color: white;
}

.tab-content {
  border: 1px solid #ddd;
  padding: 20px;
  width: 100%;
}
.chat-container {
  max-width: 600px;
  margin: auto;
  border: 1px solid #ddd;
  padding: 15px;
  overflow-y: auto;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.user-message {
  background-color: #e0f7fa;
  text-align: left;
}

.operator-message {
  background-color: #e0e0e0;
  text-align: right;
}

.status-label {
  display: block;
  font-size: 0.8em;
  color: #00796b;
}

.message-header {
  margin-bottom: 5px;
}

.sender-label {
  font-size: 0.8em;
  font-weight: bold;
  color: #555;
}

.message-body {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f3f3f3;
}

.quality-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.high-quality-btn {
  background-color: #b2fab4; /* Light green */
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.low-quality-btn {
  background-color: #fbb2b2; /* Light red */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.operator-message img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto; /* pushes the block-level image to the right */
  margin-top: 10px;
  border-radius: 8px;
}

.modal {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal textarea {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.label-button {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Styles for Low Quality Correction Modal */
.low-quality-correction-modal {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fefefe;
}

.original-message {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.correction-textarea {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.correction-submit-btn, .correction-cancel-btn {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.correction-submit-btn {
  background-color: #4CAF50; /* Light green */
  color: white;
}

.correction-cancel-btn {
  background-color: #f44336; /* Light red */
  color: white;
  margin-left: 10px;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  padding-top: 20px; /* Adjust as needed for spacing */
}

.submit-button {
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50; /* Example color */
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button:disabled {
  background-color: #A5A5A5; /* Greyed-out color */
  cursor: default;
}

.corrections-table {
  display: flex;
  flex-direction: column;
}

.table-header, .table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.header-item, .content-item {
  width: 50%;
}

.row-content {
  display: flex;
}

.content-item {
  padding: 5px;
  border-right: 1px solid #ccc;
}

/* Remove border for the last item */
.row-content .content-item:last-child {
  border-right: none;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.pagination .active {
  background-color: blue;
  color: white;
}

.user-message {
  background-color: #e7f3fe; /* Light blue for user messages */
}

.assistant-message {
  background-color: #ffefc7; /* Light yellow for assistant messages */
}

.date-inputs {
  margin-bottom: 20px;
}

.date-inputs label {
  margin-right: 10px;
  font-weight: bold;
}

.date-inputs input[type="date"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-right: 20px;
}

.emergency-reset-btn {
  position: fixed; /* or absolute depending on your layout */
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* to ensure it's above other elements */
}


.profiles-container {
    position: fixed;
    top: 60px; /* Adjust this based on your header's height */
    left: 0;
    right: 0;
    z-index: 1; /* Ensure it's above other elements */
    display: flex;
    justify-content: space-between;
    padding: 0 20px; /* Add some horizontal padding */
  }

  .profile {
    flex-basis: 20%; /* Adjust width as needed */
    max-width: 300px; /* Set a maximum width */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    overflow-y: auto; /* Enable scrolling within profiles */
    max-height: 90vh; /* Limit height to avoid overlapping with footer */
  }

  .real-profile {
    background-color: #e0f7fa;
    position: fixed;
    left: 20px; /* Adjust as needed */
    top: 70px; /* Adjust based on header's height */
  }

  .fake-profile {
    background-color: #e0e0e0;
    position: fixed;
    right: 20px; /* Adjust as needed */
    top: 70px; /* Adjust based on header's height */
  }

</style>
