<template>
  <div>
    <h2>User Messages Report</h2>
    <div class="date-inputs">
      <label for="startDate">Start Date:</label>
      <input type="date" id="startDate" v-model="startDate">

      <label for="endDate">End Date:</label>
      <input type="date" id="endDate" v-model="endDate">
    </div>
    <button @click="downloadCSVForAllUsers">Download CSV for All Users</button>
    <h3>Total Messages Sent: {{ total_messages }}</h3>
    <table>
      <tr>
        <th>Username</th>
        <th>Messages Sent</th>
      </tr>
      <tr v-for="user in users" :key="user.username">
        <td>
          <a :href="`/recent-messages/${user.username}`" target="_blank">{{ user.username }}</a>
        </td>
        <td>{{ user.messages_sent }}</td>
      </tr>
    </table>
  </div>
</template>


<script>
import { formatStartOfMonth, formatToday, formatDate } from '@/utils/dateUtils';
import levenshtein from 'js-levenshtein';

export default {
  data() {
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');

    return {
      users: [],
      total_messages: 0,
      startDate: storedStartDate || formatStartOfMonth(),
      endDate: storedEndDate || formatToday(),
    };
  },
  created() {
    this.getToken();
    this.fetchData();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchData();
    }, 3000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    getToken() {
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async fetchData() {
      try {
          await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/all-messages-by-date/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({
            start: this.startDate + 'T00:00:00',
            end: this.endDate + 'T23:59:59',
          }),
        }).then(response => response.json())
        .then(data => {
          this.users = data.users;
          this.total_messages = data.total_messages_sent;
        })
        .catch(error => console.error('Error:', error));
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    async fetchUserMessages(username) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/recent-messages/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({
            start: this.startDate + 'T00:00:00',
            end: this.endDate + 'T23:59:59',
            username
          }),
        });
        const data = await response.json();
        return data.data;
      } catch (error) {
        console.error('Error fetching messages for user:', username, error);
        return [];
      }
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    calculateSimilarity(response, actualResponse) {
      if (!response || !actualResponse) {
        return 0;
      }
      const distance = levenshtein(response, actualResponse);
      const maxLength = Math.max(response.length, actualResponse.length);
      const similarity = ((maxLength - distance) / maxLength) * 100;
      return similarity.toFixed(2);
    },
    normalizeField(field) {
      if (field === null || field === undefined) {
        return '';
      }
      return field
        .replace(/"/g, '""') // Escape double quotes
        .replace(/’/g, "'")  // Replace typographical apostrophe with ASCII apostrophe
        .replace(/`/g, "'"); // Replace grave accent with ASCII apostrophe
    },
    async downloadCSVForAllUsers() {
      const headers = ["Timestamp", "Username", "Last Message", "AI Response", "Actual Response", "Similarity Rate", "Geo"];
      const rows = [];

      for (const user of this.users) {
        const messages = await this.fetchUserMessages(user.username);
        messages.forEach(item => {
          rows.push([
            `"${this.formatTimestamp(item.timestamp)}"`,
            `"${user.username}"`,
            `"${this.normalizeField(item.last_message.content)}"`,
            `"${this.normalizeField(item.response)}"`,
            `"${this.normalizeField(item.actual_response)}"`,
            `"${this.calculateSimilarity(item.response, item.actual_response)}%"`,
            `"${this.normalizeField(item.geo)}"`
          ]);
        });
      }

      const csvContent = [
        headers.map(header => `"${header}"`).join(","),
        ...rows.map(e => e.join(","))
      ].join("\n");

      const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "messages.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  watch: {
    startDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('startDate', formattedDate);
      this.fetchData();
    },
    endDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('endDate', formattedDate);
      this.fetchData();
    },
  },
};
</script>


<style>
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
}

h2, h3 {
  color: #0056b3;
}

.date-inputs {
  margin-bottom: 20px;
}

.date-inputs label {
  margin-right: 10px;
  font-weight: bold;
}

.date-inputs input[type="date"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-right: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #0066cc;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

a {
  color: #0056b3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  th, td {
    text-align: right;
  }

  td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}
</style>