function formatStartOfMonth() {
    const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    return formatDate(startOfMonth);
  }
  
  function formatToday() {
    const currentDate = new Date();
    return formatDate(currentDate);
  }
  
  function formatDate(date) {
    return date.getFullYear() + '-' +
           ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
           ('0' + date.getDate()).slice(-2);
  }
  
  export { formatStartOfMonth, formatToday, formatDate };
  